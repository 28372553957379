<template>
  <div id="received" class="text-center white--text primary py-12">
    <h2 class="display-1 font-weight-bold mb-6">404 - Page not found</h2>
    <p class="mb-10">Something went wrong</p>
    <v-btn to="/" color="white" class="primary--text">Go back to home</v-btn>
  </div>
</template>

<script>
export default {
  name: "Received",
};
</script>

<style></style>
